import React, { useEffect, useState, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { getlocker } from '../../apis/locker';
import { sockets } from '../../apis/socket';
// import './picchart.css';

const Pie_chart = () => {
  const renderAfterCalled = useRef(false);

  const [availabledata, setavailabledata] = useState(0);
  const [occupieddata, setoccupieddata] = useState(0);
  const [lockstatus, setLockStatus] = useState({
    lock: 0,
    open: 0,
  });
  const getroomdata = async () => {
    // setavailabledata(0);
    // setoccupieddata(0);
    var alllocker = await getlocker();
    const occupiedlocker = await alllocker.filter((data) => {
      return data.user !== null;
    });
    const availablelocker = await alllocker.filter((data) => {
      return data.user === null;
    });
    const lockerstatus = await alllocker.filter((data) => {
      return data.status === 'lock';
    });
    const openerstatus = await alllocker.filter((data) => {
      return data.status === 'open';
    });

    setavailabledata(availablelocker.length);
    setoccupieddata(occupiedlocker.length);
    setLockStatus({
      ...lockstatus,
      lock: lockerstatus.length,
      open: openerstatus.length,
    });
  };
  const getOption = () => ({
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    series: [
      {
        name: 'Data',
        type: 'pie',
        radius: '55%',
        center: ['50%', '50%'],
        animationDuration: 2000,
        data: [
          { value: availabledata, name: 'Available' },
          { value: occupieddata, name: 'Occupied' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  });
  const getOption1 = () => ({
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    color: ['#F28E1F', '#2995B3'],
    series: [
      {
        name: 'Data',
        type: 'pie',
        radius: '55%',
        center: ['50%', '50%'],
        animationDuration: 2000,
        data: [
          { value: lockstatus.lock, name: 'Lock' },
          { value: lockstatus.open, name: 'Open' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  });
  useEffect(() => {
    if (!renderAfterCalled.current) {
      getroomdata();
      sockets.off('locker').on('locker', async (data) => {
        if (data === 'reload') {
          console.log('reload');
          await getroomdata();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div className='open-locker'>
      <div className='chartcontainer'>
        <div className='availablechart'>
          <ReactEcharts
            option={getOption()}
            style={{ height: '200px', width: '80%' }}
          />
          <div className='pie_chart_status_container'>
            <div className='pie_chart_status pie_chart_status_1'>
              <p>{availabledata}</p>
              <p>Available</p>
            </div>
            <div className='pie_chart_status'>
              <p>{occupieddata}</p>
              <p>Occupied</p>
            </div>
          </div>
        </div>
        <div className='availablechart'>
          <ReactEcharts
            option={getOption1()}
            style={{ height: '200px', width: '80%' }}
          />
          <div className='pie_chart_status_container'>
            <div className='pie_chart_status pie_chart_status_1'>
              <p>{lockstatus.lock}</p>
              <p>Lock</p>
            </div>
            <div className='pie_chart_status'>
              <p>{lockstatus.open}</p>
              <p>Open</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pie_chart;
