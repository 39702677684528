import { Localadmin } from "../axios/index";

export const Newregister = async (data) => {
  var newregister = await Localadmin
    .post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return newregister;
};

export const Getadminall = async () => {
  var alladminall = await Localadmin.get(`/viewall`).then((res) => {
    return res.data;
  });
  return alladminall;
};