import { Locker } from '../axios/index';

export const newlocker = async (data) => {
  var newregister = await Locker.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return newregister;
};

export const getlocker = async () => {
  var alllocker = await Locker.get(`/viewall`).then((res) => {
    return res.data;
  });
  return alllocker;
};

export const openlocker = async (data) => {
  var alllocker = await Locker.post(`/lockrelease`, data).then((res) => {
    return res.data;
  });
  return alllocker;
};

export const viewlocker = async (data) => {
  var viewlocker = await Locker.post(`/view`, data).then((res) => {
    return res.data;
  });
  return viewlocker;
};

export const updatelocker = async (data) => {
  var updatelocker = await Locker.post(`/update`, data).then((res) => {
    return res.data;
  });
  return updatelocker;
};

export const updatenewlocker = async (data) => {
  var updatelocker = await Locker.post(`/lockerupdate`, data).then((res) => {
    return res.data;
  });

  return updatelocker;
};
