import React, { useState, useEffect } from "react";
import InputField from "../components/input/InputField";
import { FaRegEdit } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import "../Styles/EditLocation.css";
import logolocker from "../assests/locker.svg";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  newlocation,
  getlocation,
  viewlocation,
  updatelocation,
} from "../apis/location";

function EditLocation() {
  const [isEdit, setisEdit] = useState(false);
  const [alllocation, setalllocation] = useState([]);
  const [editid, seteditid] = useState(null);
  const editHandler = () => {
    setisEdit(true);
    seteditid(null);
    document.getElementById("location").value = "";
  };
  const savebtn = async () => {
    var location = document.getElementById("location").value;
    if (location.length === 0) {
      toast.error("Location Name is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        name: location,
      };
      var createlocation = await newlocation(data);
      if (createlocation === "Registration successful") {
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    getlocationdata();
  }, []);

  const getlocationdata = async () => {
    var alllocationdata = await getlocation();
    setalllocation(alllocationdata);
  };
  const editbtn = async (e) => {
    setisEdit(true);
    seteditid(e.target.id);
    var singledata = await viewlocation({ id: e.target.id });
    if (singledata.length !== 0) {
      document.getElementById("location").value = singledata[0].name;
    }
  };
  const updatebtn = async () => {
    var location = document.getElementById("location").value;
    if (location.length === 0) {
      toast.error("Location Name is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        name: location,
        id: editid,
      };
      var createlocation = await updatelocation(data);
      if (createlocation === "Updated Successfully") {
        window.location.reload();
      }
    }
  };
  return (
    <div className="dashboard-container">
      <div className="page-header">
        <div className="page-title">
          <span className="title-logo">
            <FaRegEdit />
          </span>
          <h1>Edit Location</h1>
        </div>
        {/* <div> */}
        {/* <InputField placeholder="Search" icon={<BiSearch />} /> */}
        {/* </div> */}
      </div>
      <div className="Edit_locations_container">
        <div className="locker-set-up">
          <div className="space-between">
            <h3 className="card-header">Location Set Up</h3>
            <button onClick={editHandler}>Add Location</button>
          </div>
          <div className="card-head">
            {alllocation.length !== 0
              ? alllocation.map((item, index) => {
                  return (
                    <>
                      <div className="locker-card" key={index}>
                        <div className="locker-icon">
                          <img
                            src={logolocker}
                            alt="locker"
                            className="locker-svg"
                          />
                        </div>
                        <div className="text-center">
                          <h4>{item.name}</h4>
                        </div>

                        <div className="locker-card-buttons-head">
                          <button
                            className="locker-card-buttons"
                            id={item.id}
                            onClick={editbtn}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
        {isEdit && (
          <div className="Location_edit_details">
            <div className="locker-card-1">
              <div className="locker-icon">
                <img src={logolocker} alt="locker" className="locker-svg" />
              </div>
              <div className="text-center">
                <input placeholder="Enter location" id="location" />
              </div>

              <div className="locker-card-buttons-head">
                {editid === null ? (
                  <button className="locker-card-buttons" onClick={savebtn}>
                    Save
                  </button>
                ) : (
                  <button className="locker-card-buttons" onClick={updatebtn}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditLocation;
