import React, { useMemo, useState, useEffect, useRef } from 'react';
import { CgNotes } from 'react-icons/cg';
import '../Styles/Report1.css';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import MaterialReactTable, {
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
} from 'material-react-table';
import {
  IconButton,
  Box,
  Tooltip,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material';
import { Slide, ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { sockets } from '../apis/socket';

const Report1 = () => {
  const renderAfterCalled = useRef(false);

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [report, setReport] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  // const globalTheme = useTheme();

  // const tableTheme = useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode: 'dark',
  //         primary: {
  //           main: '#1de9b6',
  //         }, // swap in the secondary color as the primary for the table // loading progress
  //         // secondary: {
  //         //     main: '#8c9eff',
  //         // },
  //         info: {
  //           main: 'rgb(255, 255, 255,0.6)', // add in a custom color for the toolbar alert background stuff
  //         },
  //         background: {
  //           default:
  //             globalTheme.palette.mode === 'light'
  //               ? 'rgb(255, 255, 255,0.6)' // random light yellow color for the background in light mode
  //               : '#000', // pure black table in dark mode for fun
  //         },

  //         typography: {
  //           textTransform: 'none', // customize typography styles for all buttons in table by default
  //           fontFamily: 'Raleway, Arial',
  //           // fontSize: '1rem',
  //         },
  //         // components: {
  //         //     MuiTooltip: {
  //         //         styleOverrides: {
  //         //             tooltip: {
  //         //                 fontSize: '15.1rem', // override to make tooltip font size larger
  //         //             },
  //         //         },
  //         //     },
  //         //     MuiSwitch: {
  //         //         styleOverrides: {
  //         //             thumb: {
  //         //                 color: 'pink', // change the color of the switch thumb in the columns show/hide menu to pink
  //         //             },
  //         //         },
  //         //     },
  //         // },
  //       },
  //     }),
  //   [globalTheme]
  // );

  const columns = useMemo(
    () => [
      {
        accessorFn: (row, index) => index + 1,
        id: 'no',
        header: 'No',
        minSize: 3, // min size enforced during resizing
        maxSize: 10, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'locker',
        header: 'Locker',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'date',
        header: 'Booked Date',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'name',
        header: 'Name',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'fromtime',
        header: 'Start Time',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'totime',
        header: 'End Time',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        accessorKey: 'location_name',
        header: 'Location',
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
      {
        // accessorFn: (row, index) => index + 1,
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => (
          <p
            style={{
              color:
                cell.getValue() === 'Occupied'
                  ? 'orange'
                  : cell.getValue() === 'Completed'
                  ? 'green'
                  : 'red',
              fontSize: '14px',
            }}
          >
            {cell.getValue()}
          </p>
        ),
        minSize: 3, // min size enforced during resizing
        maxSize: 50, // max size enforced during resizing
        size: 0, // medium column
      },
    ],
    []
  ); // column definitions...
  const fetch = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/report`)
      .then((res) => {
        if (res) {
          setReport(res.data?.report ?? []);
          setLoading(false);
        }
        return res;
      })
      .catch((err) => {
        setIsError(true);
        setLoading(false);
        return err;
      });
  };
  const exportbtn = async () => {
    var filterarray = [];
    for (var i = 0; i < report.length; i++) {
      filterarray.push({
        No: i + 1,
        Locker: report[i].locker,
        Booked_Date: report[i].date,
        Name: report[i].name,
        Start_Time: report[i].fromtime,
        End_Time: report[i].totime,
        Location: report[i].location_name,
        Status: report[i].status,
      });
    }
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(filterarray);
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'report.xlsx');
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetch();
      sockets.off('lockerbooking').on('lockerbooking', async (data) => {
        if (data === 'reload') {
          fetch();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div className='dashboard-container'>
      <div className='page-header'>
        <div className='page-title'>
          <span className='title-logo'>
            <CgNotes />
          </span>
          <h1>Report</h1>
        </div>
      </div>
      <div className='tablecontainer'>
        {/* <ThemeProvider theme={tableTheme}> */}
        <MaterialReactTable
          columns={columns}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          data={report}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '65vh' } }}
          initialState={{ density: 'compact' }}
          // muiTableContainerProps={{
          //     sx: { maxHeight: '300px' }, // give the table a max height
          // }}

          // muiTableBodyProps={{
          //   sx: {
          //     // stripe the rows, make odd rows a darker color
          //     '& tr:nth-of-type(odd)': {
          //       backgroundColor: '#BEBEBE',
          //     },
          //   },
          // }}
          // defaultColumn={{
          //   minSize: 3, // min size enforced during resizing
          //   maxSize: 500, // max size enforced during resizing
          //   size: 0, // medium column
          // }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          muiTablePaginationProps={{
            rowsPerPageOptions: [15, 20, 30, 50, 150, 200, 300, 500, 1000],
          }}
          onPaginationChange={setPagination}
          renderTopToolbarCustomActions={() => (
            <Tooltip arrow title='Refresh Data'>
              <IconButton onClick={() => fetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <MRT_ToggleGlobalFilterButton
                table={table}
                style={{ color: '#cfd8dc' }}
              />
              <MRT_ToggleFiltersButton
                table={table}
                style={{ color: '#cfd8dc' }}
              />
              <MRT_ShowHideColumnsButton
                table={table}
                style={{ color: '#cfd8dc' }}
              />
              <Tooltip arrow title='Export Excel'>
                <IconButton onClick={exportbtn} style={{ color: '#cfd8dc' }}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>

              <MRT_ToggleDensePaddingButton
                table={table}
                style={{ color: '#cfd8dc' }}
              />
              <MRT_FullScreenToggleButton
                table={table}
                style={{ color: '#cfd8dc' }}
              />
            </Box>
          )}
          // displayColumnDefOptions={{
          //   'mrt-row-actions': {
          //     header: 'Action', // change header text
          //     size: 0, // make actions column wider
          //   },
          // }}
          // enableRowActions
          //  positionActionsColumn='last'
          // renderRowActions={({ row }) => (
          //   <Box sx={{ display: 'flex' }}>
          //     <IconButton
          //       onClick={() => deletedata(row?.original?._id ?? null)}
          //     >
          //       <DeleteIcon />
          //     </IconButton>
          //   </Box>
          // )}
          state={{
            showProgressBars: loading,
            // showSkeletons: loading,
            showAlertBanner: isError,
            pagination,
          }}
        />
        {/* </ThemeProvider> */}
      </div>
    </div>
  );
};

export default Report1;
