import React, { useState, useEffect } from "react";
import "../Styles/localAdmin.css";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Newregister, Getadminall } from "../apis/localadmin";
import { getlocation } from "../apis/location";

const LocalAdmin = () => {
  const [newuser, setnewuser] = useState(false);
  const [alllocation, setalllocation] = useState([]);
  const [alladmindata, setalladmindata] = useState([]);
  const RegisterHandler = async () => {
    var name = document.getElementById("name").value;
    var phone = document.getElementById("phone").value;
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var role = document.getElementById("role").value;
    var location = document.getElementById("location").value;
    if (name.length === 0) {
      toast.error("Name is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (phone.length === 0) {
      toast.error("Mobile Number is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (email.length === 0) {
      toast.error("Email is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password.length === 0) {
      toast.error("Password is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (role.length === 0) {
      toast.error("Role is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (location.length === 0) {
      toast.error("Location is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        mobile: phone,
        email: email,
        password: password,
        name: name,
        location: location,
        role: role,
      };
      var creteadmin = await Newregister(data);
      if (creteadmin !== null) {
        toast.success("Local Admin Created", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };
  const newadmin = () => {
    setnewuser(true);
  };
  useEffect(() => {
    getlocationdata();
  }, []);

  const getlocationdata = async () => {
    var alllocationdata = await getlocation();
    setalllocation(alllocationdata);
    var alladmin = await Getadminall();
    setalladmindata(alladmin);
  };
  const selectrole = async (e) => {
    var alladmin = await Getadminall();
    if (e.target.value.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < alladmin.length; i++) {
        if (alladmin[i].role === e.target.value) {
          filterdata.push(alladmin[i]);
        }
      }
      setalladmindata(filterdata);
    }
  };
  return (
    <div>
      {" "}
      <div className="dashboard-container">
        <div className="page-header">
          <div className="page-title">
            <span className="title-logo"></span>

            <h1>Admin Setting</h1>
          </div>
        </div>
        {newuser === false ? (
          <div className="locker-set-up">
            <div className="card-header">
              <div className="d-flex">
                <h3 className="report-card-head">Locker Report</h3>
                <button onClick={newadmin}>New Admin</button>
                <select style={{ width: "17%" }} onChange={selectrole}>
                  <option value="" disabled selected hidden>
                    Select Role
                  </option>
                  <option value="Local Admin">Local Admin</option>
                  <option value="Super Admin">Super Admin</option>
                </select>
              </div>
            </div>
            <div className="table-head">
              <div className="head-table">
                <table style={{ width: "100%", textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Role</th>
                      <th>Location</th>
                    </tr>
                  </thead>

                  <tbody>
                    {alladmindata.length !== 0
                      ? alladmindata.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.role}</td>
                            <td>{item.location}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="Local_admin_container">
            <div className="open-locker Local_admin_inputs">
              <h2>Admin Registration</h2>
              <select className="selecttag">
                <option value="" disabled selected hidden>
                  Select Role
                </option>
                <option value="Local Admin">Local Admin</option>
                <option value="Super Admin">Super Admin</option>
              </select>
              <select className="selecttag" id="location">
                <option value="" disabled selected hidden>
                  Location
                </option>
                {alllocation.length !== 0 ? (
                  alllocation.map((data, index) => (
                    <option value={data.name} key={index}>
                      {data.name}
                    </option>
                  ))
                ) : (
                  <option>Add Location</option>
                )}
              </select>
              <input placeholder="Name" id="name" />
              <input placeholder="Mobile Number" id="phone" />
              <input placeholder="Email " id="email" />
              <input placeholder="Password" id="password" />
              <button onClick={RegisterHandler}>Register</button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LocalAdmin;
