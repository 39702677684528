import axios from "axios"

export const Users = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/users`
})

export const Lockerbooking = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/lockerbooking`
})

export const Locker = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/locker`
})

export const Location = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/location`
})

export const Admin = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/admin`
})

export const Localadmin = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/localadmin`
})