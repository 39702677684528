import { configureStore, createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLogin: false,
    isRegister: true,
  },
  reducers: {
    loginhandler(state) {
      state.islogin = true;
    },
    Register(state) {
      state.isRegister = !state.isRegister;
    },
  },
});
export const loginAction = loginSlice.actions;
const Store = configureStore(loginSlice);
export default Store;
