import React from 'react'

function Terms() {
  return (
    <>
     <div className='center'>
    <div className='card-set-up card-width-300'>
        <div className='text-center'>
            <h3 className='my-2'>Welcome To Smart Locker</h3>
            <h4 className='my-2'>Terms & Conditions</h4>
        </div>
        <div className='terms-conditions'>
            <p className='my-2'>
            If users abuse your website or mobile app in any way, you can terminate their account.<br/>
            Your "Termination" clause can inform users that their accounts would be terminated<br/>
            if they abuse your service.
            </p>
            <p className='my-2'>
            If users abuse your website or mobile app in any way, you can terminate their account.
            Your "Termination" clause can inform users that their accounts would be terminated
            if they abuse your service.
            </p>
            <p className='my-2'>
            If users abuse your website or mobile app in any way, you can terminate their account.
            </p>
        </div>
        <div className='d-flex my-3'>
        {/* <input type="checkbox" id="accept" name="accept" value="accept"/> */}
        <div className='checkbox mx-1'></div>
        <div><p>I have read and accept the terms & conditions</p></div>
       
        </div>

    </div>
    </div>
    </>
  )
}

export default Terms