import React, { useEffect } from "react";
import "../../Styles/Login.css";
// import logo from "../../assests/Logo.png";
import logo from "../../assests/Logo1.png";
import { useDispatch } from "react-redux";
import { loginAction } from "../../Store/Store";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Adminlogin } from '../../apis/admin'
const Singin = () => {
  var dispatch = useDispatch();
  const register_handler = () => {
    dispatch(loginAction.Register());
  };
  useEffect(() => {
    var userid = sessionStorage.getItem("userid")
    if (userid !== null) {
      window.location.replace("/dashboard")
    }
  }, [])
  const signbtn = async () => {
    var email = document.getElementById("email").value
    var password = document.getElementById("password").value
    if (email.length === 0) {
      toast.error("Email is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password.length === 0) {
      toast.error("Password is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        email: email,
        password: password
      }
      var loginuser = await Adminlogin(data)
      if (loginuser[0].id !== undefined) {
        sessionStorage.setItem("userid", loginuser[0].id);
        dispatch(loginAction.loginhandler());
        window.location.replace("/dashboard");
      } else {
        toast.error(loginuser, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  }
  return (
    <div className="login-container">
      <center>
        <img src={logo} alt="" />
      </center>
      <div className="Login_fields">
        <div className="Login_input">
          <label>E-mail</label>
          <input placeholder="Enter you e-mail" id="email" />
        </div>
        <div className="Login_input">
          <label>Password</label>
          <input placeholder="Enter you password" type="password" id="password" />
        </div>
      </div>
      {/* <p onClick={register_handler}>Don't have Account ? Register</p> */}
      <button onClick={signbtn}>Login</button>
      <ToastContainer />
    </div>
  );
};

export default Singin;
