import { Admin } from '../axios'


export const Adminlogin = async (data) => {
    var Login = await Admin
      .post(`/login`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return Login;
  };
  