import React, { useState, useEffect } from 'react';
import InputField from '../components/input/InputField';
import { FaRegEdit } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';
import InputSelect from '../components/input/InputSelect';
import '../Styles/EditLocker.css';
import { locker } from '../helper/Locker';
import logolocker from '../assests/locker.svg';
import { getlocation } from '../apis/location';
import { toast, Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { newlocker, getlocker, viewlocker, updatelocker } from '../apis/locker';

function EditLocker() {
  const [isEdit, setisEdit] = useState(false);
  const [alllocation, setalllocation] = useState([]);
  const [alllocker, setalllocker] = useState([]);
  const [updatelockerid, setupdatelockerid] = useState(null);
  const editHandler = () => {
    setisEdit(true);
    setupdatelockerid(null);
  };
  useEffect(() => {
    getlocationdata();
  }, []);

  const getlocationdata = async () => {
    var alllocationdata = await getlocation();
    var alllocker = await getlocker();
    setalllocker(alllocker);
    setalllocation(alllocationdata);
  };
  const savebtn = async () => {
    var location = document.getElementById('location').value;
    var number = document.getElementById('number').value;
    var subscribe = document.getElementById('subscribe').value;
    var publish = document.getElementById('publish').value;
    var device = document.getElementById('device').value;
    var price = document.getElementById('price').value;
    var message = document.getElementById('message').value;
    if (location === 'null') {
      toast.error('Location Name is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (number.length === 0) {
      toast.error('Locker Number is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (subscribe.length === 0) {
      toast.error('Subscribe Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (publish.length === 0) {
      toast.error('Publish Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (device.length === 0) {
      toast.error('Device Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (price.length === 0) {
      toast.error('Price is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (message.length === 0) {
      toast.error('Message is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        locker_number: number,
        status: 'lock',
        subscribe_topic: subscribe,
        publish_topic: publish,
        message: message,
        device_topic: device,
        device_on_message: '1',
        device_off_message: '0',
        location: location,
        price: price,
      };
      var createlocker = await newlocker(data);
      if (createlocker?.message) {
        toast.success(createlocker.message, {
          autoClose: 2000,
          transition: Slide,
        });
        await getlocationdata();
        //  window.location.reload();
      }
    }
  };
  const editbtn = async (e) => {
    setisEdit(true);
    setupdatelockerid(e.target.id);
    var singlelocker = await viewlocker({ id: e.target.id });
    if (singlelocker.length !== 0) {
      document.getElementById('number').value = singlelocker[0].locker_number;
      document.getElementById('subscribe').value =
        singlelocker[0].subscribe_topic;
      document.getElementById('publish').value = singlelocker[0].publish_topic;
      document.getElementById('device').value = singlelocker[0].device_topic;
      document.getElementById('price').value = singlelocker[0].price;
      document.getElementById('message').value = singlelocker[0].message;
    }
  };
  const updatebtn = async () => {
    var location = document.getElementById('location').value;
    var number = document.getElementById('number').value;
    var subscribe = document.getElementById('subscribe').value;
    var publish = document.getElementById('publish').value;
    var device = document.getElementById('device').value;
    var price = document.getElementById('price').value;
    var message = document.getElementById('message').value;

    if (location === 'null') {
      toast.error('Location Name is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (number.length === 0) {
      toast.error('Locker Number is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (subscribe.length === 0) {
      toast.error('Subscribe Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (publish.length === 0) {
      toast.error('Publish Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (device.length === 0) {
      toast.error('Device Topic is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (price.length === 0) {
      toast.error('Price is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (message.length === 0) {
      toast.error('message is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var datanew = {
        locker_number: number,
        subscribe_topic: subscribe,
        publish_topic: publish,
        message: message,
        device_topic: device,
        device_on_message: '1',
        device_off_message: '0',
        location: location,
        price: price,
        id: updatelockerid,
      };
      var updatenew = await
        (datanew);
      if (updatenew?.message) {
        toast.success(updatenew, {
          autoClose: 2000,
          transition: Slide,
        });
        await getlocationdata();
        // window.location.reload();
      }

      // if (updatenew === 'Updated Successfully') {
      //   window.location.reload();
      // }
    }
  };
  const cancelbtn = () => {
    setisEdit(false);
  };
  const getlocationvalue = async (e) => {
    var alllocker = await getlocker();
    var singlelocker = await alllocker.filter((data) => {
      return data.location === e.target.value;
    });
    setalllocker(singlelocker);
  };
  return (
    <div className='dashboard-container'>
      <div className='page-header'>
        <div className='page-title'>
          <span className='title-logo'>
            <FaRegEdit />
          </span>
          <h1>Edit Locker</h1>
        </div>
        {/* <div> */}
        {/* <InputField placeholder="Search" icon={<BiSearch />} /> */}
        {/* </div> */}
      </div>
      <div className='Edit_locations_container'>
        {!isEdit && (
          <div className='locker-set-up'>
            <div className='card-header'>
              <div className='space-between'>
                <h3 className='card-header'>Locker Set Up</h3>
                <button onClick={editHandler}>Add Locker</button>
              </div>
              {/* <div className="locker-select">
                <div>Location </div>
                <div className="">
                  <select
                    id="choose3"
                    name="choose3"
                    className="input-container-select inputWidth-25"
                    onChange={getlocationvalue}
                  >
                    <option value="" disabled selected hidden>
                      Location
                    </option>
                    {alllocation.length !== 0 ? (
                      alllocation.map((data, index) => (
                        <option value={data.id} key={index}>
                          {data.name}
                        </option>
                      ))
                    ) : (
                      <option>Add Location</option>
                    )}
                  </select>
                </div>
                <button onClick={getlocationdata}>Reset</button>
              </div> */}
            </div>
            <div className='card-head'>
              {alllocker.length !== 0
                ? alllocker.map((item, index) => {
                    return (
                      <>
                        <div className='locker-card' key={index}>
                          <div className='locker-icon'>
                            <img
                              src={logolocker}
                              alt='locker'
                              className='locker-svg'
                            />
                          </div>
                          <div className='text-center'>
                            <h4>Locker {item.locker_number}</h4>
                          </div>

                          <div className='locker-card-buttons-head'>
                            <button
                              className='locker-card-buttons'
                              id={item.id}
                              onClick={editbtn}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        )}
        {isEdit && (
          <div className='Location_edit_details'>
            <div className='locker-card-2'>
              <div className='space-between'>
                <div></div>
                <button onClick={cancelbtn}>Cancel</button>
              </div>
              <div className='locker-icon'>
                <img src={logolocker} alt='locker' className='locker-svg' />
              </div>
              <div className='text-center'>
                <select placeholder='Select Location' id='location'>
                  <option value='null'>Select Location</option>
                  {alllocation.length !== 0 ? (
                    alllocation.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option>Add Location</option>
                  )}
                </select>
                <input placeholder='Locker Number' id='number' />
                <input placeholder='Subscribe Topic' id='subscribe' />
                <input placeholder='Publish Topic' id='publish' />
                <input placeholder='Device Topic' id='device' />
                <input placeholder='Price' id='price' />
                <input placeholder='Message' id='message' />
              </div>
              <div className='locker-card-buttons-head'>
                {updatelockerid === null ? (
                  <button className='locker-card-buttons' onClick={savebtn}>
                    Save
                  </button>
                ) : (
                  <button className='locker-card-buttons' onClick={updatebtn}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditLocker;
