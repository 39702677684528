import React, { useEffect, useState, useRef } from 'react';
// import io from 'socket.io-client';
import '../Styles/DashBoard.css';
import { BsGrid1X2 } from 'react-icons/bs';
import { getlocker } from '../apis/locker';
import { getlocation, viewlocation } from '../apis/location';
import mqtt from 'mqtt/dist/mqtt';
import Pie_chart from '../components/chart/PieChart';
import logolocker from '../assests/locker.svg';
import { getlockerbook } from '../apis/lockerbooking';
import { Alluser } from '../apis/users';
import { viewlocker } from '../apis/locker';
import moment from 'moment/moment';
import { sockets } from '../apis/socket';
import axios from 'axios';
import { Slide, toast } from 'react-toastify';

function DashBoard() {
  const renderAfterCalled = useRef(false);

  const [alllocation, setalllocation] = useState([]);
  const [reportdata, setreportdata] = useState([]);

  const getlocationdata = async () => {
    var alllocationdata = await getlocation();

    setalllocation(alllocationdata);

    await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/todayreport`)

      .then((res) => {
        if (res) {
          setreportdata(res.data?.report ?? []);
          // toast.success(res.data?.message, {
          //   autoClose: 2000,
          //   transition: Slide,
          // });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message, {
          autoClose: 2000,
          transition: Slide,
        });
      });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      var userid = sessionStorage.getItem('userid');
      if (userid === null) {
        window.location.replace('/');
      }

      getlocationdata();
      sockets.off('lockerbooking').on('lockerbooking', async (data) => {
        if (data === 'reload') {
          console.log('reload');

          await getlocationdata();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div className='dashboard-container'>
      <div className='page-header'>
        <div className='page-title'>
          <span className='title-logo'>
            <BsGrid1X2 />
          </span>

          <h1>DashBoard</h1>
        </div>
      </div>
      <div className='Edit_locations_container'>
        <Pie_chart />
        <div className='locker-set-up'>
          <div className='space-between'>
            <h3 className='card-header'>Location</h3>
          </div>
          <div className='card-head'>
            {alllocation.length !== 0
              ? alllocation.map((item, index) => {
                  return (
                    <>
                      <div className='locker-card' key={index}>
                        <div className='locker-icon'>
                          <img
                            src={logolocker}
                            alt='locker'
                            className='locker-svg'
                          />
                        </div>
                        <div className='text-center'>
                          <h4>{item.name}</h4>
                        </div>

                        <div className='locker-card-buttons-head'>
                          {/* <button
                            className="locker-card-buttons"
                            id={item.id}
                            onClick={editbtn}
                          >
                            Edit
                          </button> */}
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className='locker-set-up'>
        <div className='card-header'>
          <h3 className='report-card-head'>Locker Report</h3>
        </div>
        <div className='table-head'>
          <div className='head-table'>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <thead>
                <tr>
                  <th>Locker</th>
                  <th>Booked Date</th>
                  <th>Name</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Payment</th>
                </tr>
              </thead>

              <tbody>
                {reportdata.length !== 0
                  ? reportdata.map((item, index) => (
                      <tr key={index}>
                        <td>{item.locker}</td>
                        <td>{item.date}</td>

                        <td>{item.name}</td>
                        <td>{item.fromtime}</td>
                        <td>{item.totime}</td>
                        <td>{item.location_name}</td>
                        <td>{item.status}</td>
                        <td>{item.payment}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="open-locker">
        <h3>Open Locker</h3>
        <ul>
          {alldatalocker.length !== 0
            ? alldatalocker.map((data, index) => (
                <li key={index}>
                  <span>{data.location.name}</span>
                  <select onChange={getlockerdatanew}>
                    <option>Select Locker</option>
                    {data.locker.map((datanew) => (
                      <option value={datanew.id}>{datanew.id}</option>
                    ))}{" "}
                  </select>
                 
                </li>
              ))
            : null}
        </ul>
      </div> */}
    </div>
  );
}

export default DashBoard;
