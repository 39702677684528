import { Location } from "../axios/index";

export const newlocation = async (data) => {
  var newregister = await Location.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return newregister;
};

export const getlocation = async () => {
  var alllocation = await Location.get(`/viewall`).then((res) => {
    return res.data;
  });
  return alllocation;
};

export const viewlocation = async (data) => {
  var viewlocation = await Location.post(`/view`, data).then((res) => {
    return res.data;
  });
  return viewlocation;
};


export const updatelocation = async (data) => {
    var updatelocation = await Location.post(`/update`, data).then((res) => {
      return res.data;
    });
    return updatelocation;
  };
  