import React, { useState, useEffect, useRef } from 'react';
import LockClockIcon from '@mui/icons-material/LockClock';
// import { BiSearch } from 'react-icons/bi';
import '../Styles/EditLocker.css';
import { getlocation } from '../apis/location';
import logolocker from '../assests/locker.svg';
import { getlocker } from '../apis/locker';
// import mqtt from 'mqtt/dist/mqtt';
import { updatenewlocker, openlocker } from '../apis/locker';
import { Slide, toast } from 'react-toastify';
import { sockets } from '../apis/socket';

const Lockers = () => {
  const [alllocker, setalllocker] = useState([]);
  const [alllocation, setalllocation] = useState([]);
  const [occupiedlocker, setoccupiedlocker] = useState([]);
  const renderAfterCalled = useRef(false);

  const getlocationdata = async () => {
    var alllocationdata = await getlocation();
    var alllocker = await getlocker();
    setalllocker(alllocker);
    setalllocation(alllocationdata);

    var alldata = [];
    for (var i = 0; i < alllocationdata.length; i++) {
      // eslint-disable-next-line no-loop-func
      var mylocation = await alllocker.filter((data) => {
        return (
          Number(data.location) === Number(alllocationdata[i].id) &&
          data.status === 'lock'
        );
      });
      if (mylocation.length !== 0) {
        alldata.push({
          location: alllocationdata[i],
          locker: mylocation,
        });
      }
    }
    if (alllocker.length !== 0) {
      var checklocker = await alllocker.filter((data) => {
        return data.user !== null;
      });
      setoccupiedlocker(checklocker);
    }
    // setTimeout(() => {
    //   getlocationdata();
    // }, 100000);
  };

  const getlocationvalue = async (e) => {
    var alllocker = await getlocker();
    var singlelocker = await alllocker.filter((data) => {
      return data.location === e.target.value;
    });
    setalllocker(singlelocker);
  };
  const opnelocker = async (e) => {
    console.log(e);
    console.log(e.target.id);
    var data = {
      locker_id: e.target.id,
      status: 'lock',
      user: null,
      userid: sessionStorage.getItem('userid'),
    };
    var openlocker1 = await openlocker(data);
    // console.log(openlocker1);
    //successfully release lock --> open
    // console.log();
    toast.success(openlocker1.message, {
      autoClose: 2000,
      transition: Slide,
    });
    getlocationdata();
    // if (openlocker1.message === 'successfully release lock --> open') {
    //   setTimeout(() => {}, 2000);
    // }
    // var singlelocker = await alllocker.filter((data) => {
    //   return data.id === Number(e.target.id);
    // });
    // console.log(
    //   singlelocker[0].subscribe_topic,
    //   singlelocker[0].publish_topic,
    //   singlelocker[0].message
    // );
    // const client = mqtt.connect("ws://broker.emqx.io:8083/mqtt");
    // client.on("connect", function () {
    //   client.subscribe(singlelocker[0].subscribe_topic, function (err) {
    //     if (!err) {
    //       client.publish(
    //         singlelocker[0].publish_topic,
    //         singlelocker[0].message
    //       );
    //     }
    //   });
    // });
    // setTimeout(() => {
    //   getlocationdata();
    // }, 2000);
  };
  // const serchvalue = async (e) => {
  //   if (e.target.value.length !== 0) {
  //     var filterdata = [];
  //     for (var i = 0; i < alllocker.length; i++) {
  //       if (
  //         alllocker[i].locker_number
  //           .toLowerCase()
  //           .includes(e.target.value.toLowerCase())
  //       ) {
  //         filterdata.push(alllocker[i]);
  //       }
  //     }
  //     setalllocker(filterdata);
  //   }
  // };
  const releaselockerbtn = async (e) => {
    const userId = sessionStorage.getItem('userid');
    var data = {
      id: e.target.id,
      status: 'lock',
      user: null,
      userid: userId,
      lockeropentype: 'admin',
    };
    var updatelocker = await updatenewlocker(data);

    toast.success(updatelocker, {
      autoClose: 2000,
      transition: Slide,
    });
    getlocationdata();
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      getlocker();
      getlocationdata();
      sockets.off('locker').on('locker', async (data) => {
        if (data === 'reload') {
          console.log('reload');
          await getlocker();
          await getlocationdata();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);

  return (
    <div className='dashboard-container'>
      <div className='page-header'>
        <div className='page-title'>
          <span className='title-logo'>
            <LockClockIcon />
          </span>
          <h1>Lockers</h1>
        </div>
        {/* <div> */}

        {/* <div className="input-container">
          <span className="icon">
            <BiSearch />
          </span>
          <input
            className="input-field"
            type="text"
            placeholder="Search"
            onChange={serchvalue}
          />
        </div> */}
        {/* </div> */}
      </div>
      <div className='Edit_locations_container'>
        <div className='locker-set-up'>
          <div className='card-header'>
            <div className='locker-select'>
              <div>Location </div>
              <div className=''>
                <select
                  id='choose3'
                  name='choose3'
                  className='input-container-select inputWidth-25'
                  onChange={getlocationvalue}
                >
                  <option value='' disabled selected hidden>
                    Location
                  </option>
                  {alllocation.length !== 0 ? (
                    alllocation.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))
                  ) : (
                    <option>Add Location</option>
                  )}
                </select>
              </div>
              {/* <button onClick={getlocationdata}>Reset</button> */}
            </div>
          </div>
          <div className='card-head'>
            <>
              {alllocker.length !== 0
                ? alllocker.map((data, index) => (
                    <div
                      className='locker-card'
                      key={index}
                      style={
                        data.user !== null
                          ? { backgroundColor: 'red' }
                          : { backgroundColor: 'green' }
                      }
                    >
                      <div className='lockertype' key={index}>
                        {data.status === 'lock' ? 'L' : 'O'}
                      </div>
                      <div className='locker-icon'>
                        <img src={logolocker} alt='' className='locker-svg' />
                      </div>
                      <div className='text-center'>
                        <h4 style={{ color: '#fff' }}>
                          Locker {data.locker_number}
                        </h4>
                      </div>
                      <div className='locker-card-buttons-head'>
                        <button
                          className='locker-card-buttons'
                          id={data.id}
                          onClick={opnelocker}
                        >
                          Unlock
                        </button>
                      </div>
                    </div>
                  ))
                : null}
            </>
          </div>
        </div>
        <div>
          <div className='open-locker'>
            <h3>Occupied Lockers</h3>
            <ul>
              {occupiedlocker.length !== 0
                ? occupiedlocker.map((data, index) => (
                    <li key={index}>
                      <span>{data.locker_number}</span>

                      <button id={data.id} onClick={releaselockerbtn}>
                        Release User
                      </button>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lockers;
