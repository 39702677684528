import React from "react";
import { useSelector } from "react-redux";
import "../../Styles/Login.css";
import Register from "./Register";
import Singin from "./Singin";
const Login = () => {
  const isRegister = useSelector((store) => store.isRegister);

  return (
    <div className="login-wrapper">
      {isRegister && <Singin />}
      {!isRegister && <Register />}
    </div>
  );
};

export default Login;
