import './App.css';
import './App.css';
import SideBar from './components/sideBar/SideBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashBoard from './pages/DashBoard';
import EditLocation from './pages/EditLocation';
import EditLocker from './pages/EditLocker';
import Report from './pages/Report';
import Qrcode from './phone/Qrcode';
import Welcome from './phone/Welcome';
import Terms from './phone/Terms';
import Login from './pages/Login/Login';
import Lockers from './pages/Lockers';
import { useEffect, useState } from 'react';
import LocalAdmin from './pages/LocalAdmin';
import Seller from './pages/Seller';
import Report1 from './pages/Report1';

function App() {
  const [islogin, setislogin] = useState(false);
  useEffect(() => {
    var userid = sessionStorage.getItem('userid');
    if (userid !== null) {
      setislogin(true);
    }
  }, []);

  return (
    <>
      <Router>
        <div className='main-container'>
          {islogin && <SideBar />}
          <div
            style={{ flexGrow: 1, minHeight: '100vh',width:'82%' }}
            className='content-container'
          >
            <Routes>
              <Route exact path='/dashboard' element={<DashBoard />}></Route>
              <Route exact path='/' element={<Login />}></Route>
              <Route
                exact
                path='/edit-location'
                element={<EditLocation />}
              ></Route>
              <Route exact path='/edit-locker' element={<EditLocker />}></Route>
              <Route exact path='/Lockers' element={<Lockers />}></Route>
              {/* <Route exact path='/report' element={<Report />}></Route> */}
              <Route exact path='/report' element={<Report1 />}></Route>
              <Route exact path='/scan' element={<Qrcode />}></Route>
              <Route exact path='/terms' element={<Terms />}></Route>
              <Route exact path='/welcome' element={<Welcome />}></Route>
              <Route exact path='/localAdmin' element={<LocalAdmin />}></Route>
              <Route exact path='/seller' element={<Seller />}></Route>
            </Routes>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
