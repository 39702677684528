import React from 'react'
import { obj } from '../../helper/sideBarData'
import './sideBar.css'
import { NavLink } from 'react-router-dom';
// import sentosa from '../../assests/sentosa.png'
import sentosa from '../../assests/Logo1.png'
function SideBar() {
  const logoutbtn = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/");
  }
  return (
    <>
      <div className='side-bar-container '>
        <div className='logo-container'>
          <img src={sentosa} alt='logo' />
        </div>
        <div className='sidebar-menu-container'>
          <div className='menu-container'>
            {obj.map((data) => {
              // return  <div key={data.id} className='side-bar-container'>
              //   {data.icon}
              //   {data.menu_name}
              //   </div>
              return <NavLink to={data.route} className={({ isActive }) => isActive ? 'selected nav-link' : 'nav-link'}
                key={data.id}>
                {data.icon}
                <span className='menu-name'>{data.menu_name}</span>

              </NavLink>

            })}
          </div>
          <div className='width100per'>
            <button className='sign-btn' onClick={logoutbtn} >Log Out</button>
          </div>
        </div>

      </div>

    </>
  )
}

export default SideBar