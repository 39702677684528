import { CgNotes } from 'react-icons/cg';
import { BsGrid1X2 } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { FaUserCog } from 'react-icons/fa';
import LockClockIcon from '@mui/icons-material/LockClock';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export const obj = [
  {
    id: 1,
    icon: <BsGrid1X2 />,
    menu_name: 'dashboard',
    route: 'dashboard',
  },
  {
    id: 2,
    icon: <FaRegEdit />,
    menu_name: 'Edit Location',
    route: 'edit-location',
  },
  {
    id: 3,
    icon: <FaRegEdit />,
    menu_name: 'Edit Locker',
    route: 'edit-locker',
  },
  {
    id: 4,
    icon: <LockClockIcon />,
    menu_name: 'Lockers',
    route: 'Lockers',
  },
  // {
  //   id: 5,
  //   icon: <CgNotes />,
  //   menu_name: 'Report',
  //   route: 'report',
  // },
  {
    id: 7,
    icon: <CgNotes />,
    menu_name: 'Report',
    route: 'report',
  },
  {
    id: 6,
    icon: <SupervisorAccountIcon />,
    menu_name: 'Admin Setting',
    route: 'localAdmin',
  },
  // {
  //   id:7,
  //   icon:<FaUserCog/>,
  //   menu_name:"Seller",
  //   route:"seller"
  // },
];
