import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Seller = () => {
  const [show, Setshow] = useState(false);
  const [location, setLocation] = useState([]);
  const [seller, setSeller] = useState([]);
  const locationfetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_SERVER}/location/viewall`)
        .then((res) => res.data)
        .catch((err) => alert(err));
      if (data) {
        setLocation(data);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  const sellercreate = async () => {
    try {
      var name = document.getElementById('name').value;

      var phone = document.getElementById('phone').value;
      var email = document.getElementById('email').value;
      var password = document.getElementById('password').value;
      var location = document.getElementById('location').value;
      const obj = {
        name: name,
        mobile: phone,
        email: email,
        password: password,
        location: location,
      };
      console.log(obj);
      const response = await axios
        .post(`${process.env.REACT_APP_SERVER}/seller/sellercreate`, obj)
        .then((res) => {
          if (res.status === 201) {
            window.location.reload();
          }
        })
        .catch((err) => alert(err));
      console.log(response);
    } catch (error) {
      alert(error);
    }
  };
  const sellerget = async () => {
    try {
      const sellerData = await axios
        .get(`${process.env.REACT_APP_SERVER}/seller/sellerview`)
        .then((res) => res.data.seller)
        .catch((err) => alert(err));
      if (sellerData) {
        setSeller(sellerData);
      }
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    sellerget();
    locationfetch();
  }, []);

  const addSellerTemplate = () => {
    Setshow(true);
  };
  return (
    <div>
      <div className='dashboard-container'>
        <div className='page-header'>
          <div className='page-title'>
            <span className='title-logo'></span>

            <h1>Seller Setting</h1>
          </div>
        </div>
        {show === false ? (
          <div className='locker-set-up'>
            <div className='card-header'>
              <div className='d-flex'>
                <h3 className='report-card-head'>Seller Report</h3>
                <button onClick={addSellerTemplate}>Add Seller</button>
              </div>
            </div>
            <div className='table-head'>
              <div className='head-table'>
                <table style={{ width: '100%', textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Location</th>
                      <th>Last Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seller.length !== 0
                      ? seller.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                            <td>{item.location}</td>
                            <td>{item.last_active}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className='Local_admin_container'>
            <div
              onSubmit={sellercreate}
              className='open-locker Local_admin_inputs'
            >
              <h2>Seller Registration</h2>

              <select className='selecttag' id='location'>
                <option disabled selected hidden>
                  Location
                </option>
                {location.map((loc, index) => (
                  <option value={loc.name} key={index}>
                    {loc.name}
                  </option>
                ))}
              </select>
              <input placeholder='Name' id='name' />
              <input placeholder='Mobile Number' id='phone' />
              <input placeholder='Email ' id='email' />
              <input placeholder='Password' id='password' />
              <button onClick={sellercreate}>Register</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Seller;
