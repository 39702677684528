import { Users } from "../axios/index";

export const newregister = async (data) => {
  var newregister = await Users
    .post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return newregister;
};

export const Signin = async (data) => {
  var Login = await Users
    .post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Login;
};

export const Alluser = async () => {
  var alluser = await Users
    .get(`/viewall`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return alluser;
};

export const Invaliduser = async () => {
  var invaliduser = await Users
    .get(`/invaliduser`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return invaliduser;
};


export const Sendotp = async (data) => {
  var sendotp = await Users
    .post(`/sendotp`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return sendotp;
};

export const Verifyotp = async (data) => {
  var verifyotp = await Users
    .post(`/verifyotp`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return verifyotp;
};

export const Updateuser = async (data) => {
  var update = await Users
    .post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return update;
};
