import React, { useState } from "react";
import "../../Styles/Login.css";
// import logo from "../../assests/Logo.png";
import logo from "../../assests/Logo1.png";
import { useDispatch } from "react-redux";
import { loginAction } from "../../Store/Store";
import { BiUser, BiUpload } from "react-icons/bi";
import { newregister } from "../../apis/users";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from '../../components/database/firebase';

const Register = () => {
  const [profileinput, setprofileinput] = useState(false);
  const [imgurl, setimgurl] = useState(null);
  var dispatch = useDispatch();
  //   login handler
  const login_handler = () => {
    dispatch(loginAction.Register());
  };

  var signUpHandler = async () => {
    var firstname = document.getElementById("name").value;
    var lastname = document.getElementById("lastname").value
    var email = document.getElementById("email").value;
    var password = document.getElementById("password-new").value;
    var phone = document.getElementById("phone").value;
    var cpassword = document.getElementById("password-confirm").value

    if (imgurl === null) {
      toast.error("Profile is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    else if (firstname.length === 0) {
      toast.error("First Name is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    else if (lastname.length === 0) {
      toast.error("Last Name is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    else if (email.length === 0) {
      toast.error("Email is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (phone.length === 0) {
      toast.error("PhoneNumber is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters long", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password !== cpassword) {
      toast.error("Password Not Match", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        firstname: firstname,
        email: email,
        password: password,
        phone: phone,
        status: "false",
        profilepic: imgurl,
        lastname: lastname
      };
      var register = await newregister(data);
      if (register.id !== undefined) {
        toast.success("Account Created Sucessfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else {
        toast.error(register.data, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }

  };
  const uploadbtn = () => {
    setprofileinput(true)
  }
  const geturl = async (e) => {
    var file = e.target.files
    toast.info("Please Wait...", {
      autoClose: 2000,
      transition: Slide,
    });
    let file11 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("profile/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl = await file11;
    setimgurl(imgurl)
  }
  return (
    <div className="login-container">
      {" "}
      <center>
        <img src={logo} alt="" />
      </center>
      <div className="Login_fields">
        {profileinput === true ?
          <input
            icon={<BiUser />}
            type="file"
            onChange={geturl}
          /> : <p className="text-gray" onClick={uploadbtn}>
            Upload Profile Image <BiUpload />
          </p>}
        <div className="Login_input">
          <label>First Name</label>
          <input placeholder="Enter you First Name" id="name" />
        </div>
        <div className="Login_input">
          <label>Last Name</label>
          <input placeholder="Enter you Last Name" id="lastname" />
        </div>
        <div className="Login_input">
          <label>E-mail</label>
          <input placeholder="Enter you e-mail" id="email" />
        </div>
        <div className="Login_input">
          <label>Mobile Number</label>
          <input placeholder="Enter Mobile Number" id="phone" type="tel" />
        </div>
        <div className="Login_input">
          <label>Password</label>
          <input
            placeholder="Enter you password"
            type="password"
            id="password-new"
          />
        </div>
        <div className="Login_input">
          <label>Confim Password</label>
          <input
            placeholder="Enter you password"
            type="password"
            id="password-confirm"
          />
        </div>
      </div>
      <p onClick={login_handler}>Already have Account ? Login</p>
      <button onClick={signUpHandler}>Register</button>
      <ToastContainer />
    </div>
  );
};

export default Register;
