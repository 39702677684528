import React from 'react';
import "../Styles/Qrcode.css";

function Register() {
  return (
    <>
    <div className='center'>
   <div className='card-set-up card-width-200'>
<div>
<h3 className='text-center'>Scan QR Code</h3>
</div>
<div className='qr-center'>
    <img className='qr-image' src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/800px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="qr-code"/>
</div>
   </div>
    </div>
    </>
  )
}

export default Register