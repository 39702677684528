import React from 'react'

function Welcome() {
  return (
    <>
   <div className='center'>
<div className='card-set-up card-height-200'>
    <div className='text-center'>
        <h3 className=''>Welcome to Smart Locker</h3>
    </div>
    <div><button>Get Locker</button></div>
    <div><button>Open Locker</button></div>

</div>
   </div>
    </>
  )
}

export default Welcome