import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyB_Bl-g7WT48Qpqfl7BIUs9ZSvD9OsH-M8",
    authDomain: "chop-ai.firebaseapp.com",
    projectId: "chop-ai",
    storageBucket: "chop-ai.appspot.com",
    messagingSenderId: "10965824867",
    appId: "1:10965824867:web:530aaefdb96a34491f8f56",
    measurementId: "G-C0CSFWY0WT"
};
firebase.initializeApp(firebaseConfig);
// var auth = firebase.auth();
export { firebase };